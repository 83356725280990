import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import icons01 from "../images/Icons/icons01.png";
import icons02 from "../images/Icons/icons02.png";
import icons03 from "../images/Icons/icons03.png";

function WeDo() {
  return (
    <div>
      <div className="animation_line" />
      <Container fluid className="text-light">
        <Row className="wedo_main bg-dark justify-content-center">
          <div>
            <div className="wedo_ornament"></div>
            <div className="wedo_title">We can help you with:</div>
          </div>{" "}
          <Col className="landing_text_col d-flex justify-content-center" md lg="3">
            <div className="wedo_text">
              {" "}
              <Image className="wedo_graphic" src={icons01} />
              <div className="wedo_infotext">
                {" "}
                Software development for Web, Desktop & Mobile
              </div>
            </div>
          </Col>
          <Col className="landing_text_col d-flex justify-content-center" md lg="3">
            <div className="wedo_text">
              {" "}
              <Image className="wedo_graphic" src={icons02} />
              <div className="wedo_infotext"> UI/UX Design</div>
            </div>
          </Col>
          <Col className="landing_text_col d-flex justify-content-center" md lg="3">
            <div className="wedo_text">
              {" "}
              <Image className="wedo_graphic" src={icons03} />
              <div className="wedo_infotext"> 3D Graphic & Graphic Design</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WeDo;
