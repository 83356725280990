import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModelViewer from "./THREE_JS/Three";

function Landing() {
  return (
    <div>
      <div className="animation_line" />
      <Container fluid className="text-light">
        <Row className="landing_main bg-dark">
          {" "}
          <Col className="landing_text_col mt-2 d-flex justify-content-center minwidth">
            <div className="landing_text">
              {" "}
              <div className="landing_title"> Empowering your business with the right software</div>
              <div className="landing_subtext text_20">
                We deliver top-quality software tailored to your unique needs.<br/>
                Our team of experts is here to assist and advise you every step<br/>
                of the way. Together, we create solutions that drive success.
              </div>
              <div className="ornament"></div>
            </div>
          </Col>
          <Col className="landing_3d mt-5 mb-5 d-flex justify-content-center minwidth">
            <ModelViewer />
          </Col>
        </Row>
      </Container>
      <div className="animation_line"></div>
    </div>
  );
}

export default Landing;
