import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Mailing from "./Mailing";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div className="animation_line" />
      <Container fluid className="text-light">
        <Row className="footer_main bg-dark" id="footer_main">
          <Col className="footer_text mt-5 justify-content-center d-flex minwidth">
            <div>
              {" "}
              <span className="logo_text_full_footer">Ventum Artis</span>
              <div className="address">Address: Bydgoszcz / Remotely</div>
              <div className="mail">Mail: ventumartis@gmail.com</div>
              <div><Link to="/privacypolicy">Privacy policy</Link></div>
              <div className="mt-3">
                {" "}
                “Simplicity is prerequisite for reliability.” – Edsger W.
                Dijkstra
              </div>
              <div className="ornament"></div>
            </div>
          </Col>
          <Col className="email mt-5 mb-5 d-flex justify-content-center minwidth">
            <Mailing />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
