import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import career from "../images/career.svg"

function Career() {
  return (
    <div>
      <div className="animation_line"> </div>
      <Container fluid className="text-light">
        <Row className="career_main bg-dark">
          {" "}
          <Col className="career_text_col mt-5 d-flex justify-content-center minwidth">
            <div className="career_text">
              {" "}
              <div className="career_title">
                {" "}
                Unfortunately, <br />
                we have no job offers <br />
                at the moment
              </div>
              <div className="career_subtext text_20">
                Do not worry! You can send us your CV on our email. If we are looking
                <br />
                for someone new and your CV matches - we will definitely get in
                touch
              </div>
              <div className="ornament"></div>
              <div>Send CV to: ventumartis@gmail.com</div>
            </div>
          </Col>
          <Col className="career_3d mt-5 mb-5 d-flex justify-content-center minwidth">
          <Image
            className="career_graphic"
            src={career}
          />
          </Col>
        </Row>
      </Container>
      <div className="animation_line"></div>
    </div>
  );
}

export default Career;
