import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";
import c from "../images/c.svg";
import javascript from "../images/javascript.svg";
import Bootstrap from "../images/Bootstrap.png";
import Azure from "../images/Azure.png";
import blender from "../images/blender.png"
import net1 from "../images/net1.png"
import python from "../images/python.png"
import react from "../images/react.png"
import html from "../images/html.png"
import Image from "react-bootstrap/Image";


export default function Techslider() {
  return (
    <>
      <Swiper
        dir="rtl"
        slidesPerView={2}
        spaceBetween={5}
        slidesPerGroup={3}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 4,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 2,
          },
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Image className="graphic" src={c} />
        </SwiperSlide>
        <SwiperSlide>
          <Image className="tech_image" src={javascript} />
        </SwiperSlide>
        <SwiperSlide>
          <Image className="tech_image" src={Bootstrap} />
        </SwiperSlide>
        <SwiperSlide>
          <Image className="tech_image" src={react} />
        </SwiperSlide>
        <SwiperSlide>
          <Image className="tech_image" src={Azure} />
        </SwiperSlide>
        <SwiperSlide>
          <Image className="tech_image" src={net1} />
        </SwiperSlide>
        <SwiperSlide>
          <Image className="tech_image" src={python} />
        </SwiperSlide>
        <SwiperSlide>
          <Image className="tech_image" src={blender} />
        </SwiperSlide>
        <SwiperSlide>
          <Image className="tech_image" src={html} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
