import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "../background.css";
import aboutus from "../images/aboutus.svg";
import Techslider from "./Slider";

function AboutUs() {
  return (
    <div className="aboutus">
      <div className="animation-wrapper">
        <div className="particle particle-1"></div>
        <div className="particle particle-2"></div>
        <div className="particle particle-3"></div>
        <div className="particle particle-4"></div>
      </div>
      <Container fluid className="text-light">
        <Row className="aboutus_main" id="aboutus_main">
          {" "}
          <Col className="aboutus_maintext minwidth">
            <Col className="aboutus_text d-flex justify-content-center">
              <div className="aboutus_text">
              {" "}
                <div className="aboutus_title">About us</div>
                <div className="aboutus_subtext text_20">
                  At our software house, we pride ourselves on developing 
                  high-quality software products that meet the unique needs of
                  our clients. Whether you need a mobile app, web application,
                  or desktop software, we have the skills and expertise to bring
                  your project to life.
                </div>
              </div>
            </Col>
            <Col className="aboutus_technology mt-3 d-grid justify-content-center">
              <div className="aboutus_technology_text d-grid">
                Technologies we use
              </div>
              <Techslider />
            </Col>
          </Col>
          <Col className="aboutus_addtext d-grid justify-content-center minwidth">
            <Image className="aboutus_graphic" src={aboutus} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;
