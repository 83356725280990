import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Landing from "../components/Landing";
import AboutUs from "../components/AboutUs";
import ProjectGraphic from "../components/ProjectGraphic";
import Footer from "../components/Footer";
import CookieConsent from "react-cookie-consent";
import AlertWrapper from "../components/AlertWrapper";
import WeDo from "../components/WeDo";

function Layout() {
  return (
    <div>
      <CookieConsent buttonStyle={{ background: "#0887ff", color: "#fff"}} expires={150}>
      VentumArtis uses cookies on this site to improve performance. By browsing this site you are agreeing to this. For more information see our Privacy and Cookie policy.
      </CookieConsent> 
      <Landing />
      <AboutUs />
      <WeDo/>
      <AlertWrapper/>
      <ProjectGraphic />
      <Footer />
    </div>
  );
}

export default Layout;
