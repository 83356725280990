import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import "bootstrap/dist/css/bootstrap.min.css";

export const Mailing = () => {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_z8puvuz",
        "template_natyf7x",
        form.current,
        "1RE02FEjAl-AwklmR"
      )
      .then(
        (result) => {
          console.log(result.text, result.status);
          setStatusMessage("Email sent success");
        },
        (error) => {
          console.log(error.text);
        }
      );

  };
  return (
    (
        <Form ref={form} onSubmit={sendEmail}>
              <Formik
                initialValues={{ email: "", textarea: "", subject:"" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  if (!values.message) {
                    errors.message = "Required";
                  }
                  if (!values.subject) {
                    errors.subject = "Required";
                  }
                  return errors;
                }
              }
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <div>
                    <Form.Group
                      className="mb-3 d-grid"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        onSubmit={handleSubmit}
                      />
                      {errors.email && touched.email && errors.email}
                      <Form.Text className="text-muted">
                        (We will reply to the e-mail provided by you)
                      </Form.Text>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 d-grid"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        type="subject"
                        name="subject"
                        placeholder="Enter subject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.subject}
                        onSubmit={handleSubmit}
                      />
                      {errors.subject && touched.subject && errors.subject}
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        onSubmit={handleSubmit}
                      />
                      {errors.message && touched.message && errors.message}
                    </Form.Group>
                    <Button
                      className="submit_button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                    <p>{statusMessage}</p>
                  </div>
                )}
              </Formik>
            </Form>
    )
  );
};
export default Mailing;
