import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import quote from "../images/quote.svg"
function AlertWrapper() {
  return (
    <div>
      <div className="animation_line" />
      <Container fluid className="text-light">
        <Row className="alertwrapper_main bg-dark">
          {" "}
            <div className="alertwrapper_title">
              <Image className="quote" src={quote}/>
              {" "}
              The real challenge for a software house is not creating software,
              but understanding <br/> the needs and expectations of their clients.
              <Image className="quote" src={quote}/>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default AlertWrapper;
