import React, { useRef } from "react";
import { useGLTF} from '@react-three/drei'
import { useFrame } from "@react-three/fiber";

const GltfModel = (props) => {
  const ref = useRef();
  const {scene}= useGLTF('./VA.glb');
  useFrame((state, delta) => (ref.current.rotation.y += 0.003));
  return (
    <>
      <primitive
        ref={ref}
        {...props}
        dispose={null}
        object= {scene}
        scale={0.5}
      />
    </>
  );
};

export default GltfModel;