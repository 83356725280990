import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import promo_sourcing from "../images/promo_sourcing.png"

function ProjectGraphic() {
  return (
    <div>
      <div className="animation_line" />
      <Container fluid>
      <Row className="project_graphic bg-dark">
          <Image
            className="graphic"
            src={promo_sourcing}
          />
        </Row>
      </Container>
    </div>
  );
}

export default ProjectGraphic;
