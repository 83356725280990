import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ModelViewer from "../components/THREE_JS/Three";

function VACardGame() {
  return (
    <div>
      <div className="animation_line"> </div>
      <Container fluid className="text-light">
        <Row className="cardgame_main bg-dark">
          <Col>
            <div className="cardgame_text">VA Card Game</div>
          </Col>
        </Row>
        <Row className="cardgame_info bg-dark">
          <Col>
            <div className="cardgame_infotext text_20">
              A card game created by lovers of card games for other <br />{" "}
              enthusiasts of such entertainment. It was designed for those
              <br /> who like to spend time on strategic and exciting gameplay.<br/>
              Our game offers unique mechanics that make every hand <br/>
              unpredictable and full of surprises. Join us and test your skills!
            </div>
          </Col>
          <Col className="cardgame_model mt-5"><ModelViewer/></Col>
        </Row>
        <Row className="cardgame_rules bg-dark">
          <Col>
            <Image className="aboutus_graphic" src={""} />
          </Col>
          <Col>
            <div>zasady gry</div>
          </Col>
        </Row>
        <Row className="cardgame_seecards bg-dark">
          <Col>
            <div>przegląd kart</div>
          </Col>
        </Row>
      </Container>
      <div className="animation_line"></div>
    </div>
  );
}

export default VACardGame;
