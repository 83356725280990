import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Outlet, Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavDropdown from "react-bootstrap/NavDropdown";

function Header() {
  const handleClickAboutus = () => {
    const aboutus = document.getElementById("aboutus_main");
    if (aboutus) {
      aboutus.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickFooter = () => {
    const footer = document.getElementById("footer_main");
    if (footer) {
      footer.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <>
        {["sm"].map((expand) => (
          <Navbar
            key={expand}
            variant="dark"
            expand={expand}
            className="navbar"
          >
            <Container fluid>
              <Navbar.Brand>
                <span className="logo_text_full">Ventum Artis</span>
                <span className="logo_text_short">VA</span>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                className="offcanvas"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Menu
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto justify-content-end flex-grow-1 pe-3">
                      <Nav.Link>
                        <Link to="/">Home</Link>
                      </Nav.Link>
                      <Nav.Link><Link to="/" onClick={handleClickAboutus}>About us</Link></Nav.Link>
                      <Nav.Link>
                        <Link to="/career">Career</Link>
                      </Nav.Link>
                      <NavDropdown title="VA Card Game" disabled menuVariant="dark">
                        <Nav.Link>
                          <Link to="/vacardgame">Explore our cards</Link>
                        </Nav.Link>
                        <Nav.Link eventKey="disabled" disabled className="text-muted">Shop (in progress)</Nav.Link>
                      </NavDropdown>
                      <Nav.Link ><Link to="/" onClick={handleClickFooter}>Contact</Link></Nav.Link>
                      <Nav.Link disabled href="#PL">
                        PL
                      </Nav.Link>
                      <Nav.Link href="#EN">EN</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
        <Outlet />
      </>
    </div>
  );
}

export default Header;
