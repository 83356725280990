import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Header from "./components/Header";
import Layout from "./pages/Layout";
import Career from "./pages/Career";
import VACardGame from "./pages/VACardGame";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header/>}>
          <Route index element={<Layout />} />
          <Route path="career" element={<Career />} />
          <Route path="vacardgame" element={<VACardGame />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
